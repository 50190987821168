﻿( function( doc ) {
	'use strict';

	class NewsList {
		constructor() {
			this.newsId = -1;
			this.newsContainer = app.$( '#newsList-container' );
			this.loadNewsList();
		}

		loadNewsList() {

			app.$.fetch( 'api/newsEventsGetForView', {
				body: {
					newsId: this.newsId
				}
			} ).then( rsp => {

				if ( rsp.success ) {
					this.newsContainer.innerHTML = rsp.obj;

				} else {
					console.log( rsp.msg );
				}

			} );
		}
	}

	let nl = new NewsList();

} )( document );